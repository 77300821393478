<template>
  <div class="modal_container">
    <div class="modal_wrapper">
      <div
        class="modal_background"
        @click.self="$store.commit('toggleRecordModalState', 'doctorModal')"
        tabindex="0"
        id="modal_background"
      ></div>
      <div class="modal_record_component small">
        <button
          class="close-popup"
          @click.stop="$store.commit('toggleRecordModalState', 'doctorModal')"
        >
          <span></span>
          <span></span>
        </button>
        <div class="doctor">
          <div class="modal_header">
            <div class="modal_header__text">
              <h3>
                Задать вопрос специалисту
              </h3>
            </div>
            <p>
              Задайте свой вопрос и получите консультацию бесплатно
            </p>
          </div>
          <div class="input-group-wrapper" style="display: block">
            <div
              class="input-group"
              style="grid-gap: 20px; display: flex !important;"
            >
              <default-input-modal
                maska=""
                v-model="nameValue"
                type="text"
                placeholder="Ваше имя*"
                :class="{ error: error['name'] }"
              />
              <div class="select-modal-wrapper">
                <div class="start_specialty" style="margin: 0;">
                  <default-select-modal
                    :class="{ error: error['specialty'] }"
                    :funcDataVision="categoryVision"
                    :value="categoryVision(selectedCategory)"
                    placeholder="Выберите специальность"
                    valueName="selectedCategory"
                    @selectedCategory="selectedState"
                    :elementList="servicesList"
                  />
                </div>
              </div>
              <default-text-area-modal
                :rows="4"
                placeholder="Текст вопроса"
                type="text"
                v-model="textValue"
                :class="{ error: error['text'] }"
              />

              <default-input-modal
                maska="+7 (###) ###-##-##"
                v-model="phoneValue"
                type="text"
                placeholder="Номер телефона*"
                :class="{ error: error['phone'] }"
              />
            </div>
            <div style="display: flex; justify-content: center;">
              <button
                @click="sendReview"
                type="submit"
                class="popup__submit"
                id="askService"
              >
                <p>
                  Отправить
                </p>
              </button>
            </div>
            <div class="start__footer" style="order: 8;">
              Заполняя форму, Вы соглашаетесь с<br /><a
                :href="policy"
                target="_blank"
                >политикой конфиденциальности</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInputModal from "../select/DefaultInputModal.vue";
import DefaultSelectModal from "../select/DefaultSelectModal.vue";
import DefaultTextAreaModal from "../select/DefaultTextAreaModal.vue";
import { api } from "../../store/api";
export default {
  name: "DoctorModal",
  components: {
    DefaultSelectModal,
    DefaultInputModal,
    DefaultTextAreaModal,
  },
  data() {
    return {
      policy: "policy",
      selectedCategory: "",
      nameValue: "",
      textValue: "",
      phoneValue: "",
      error: {},
    };
  },
  methods: {
    sendReview() {
      const postObject = {
        name: this.nameValue,
        text: this.textValue,
        phone: this.phoneValue,
        specialty: this.selectedCategory.id,
      };
      api.fetch("POST", "doctor_questions/", postObject).then((res) => {
        if (res?.id) {
          this.$store.commit("toggleRecordModalState", "doctorModal");
          this.$store.commit(
            "toggleMessageModalText",
            "Ваш вопрос отправлен!"
          );
          this.$store.commit("showMessageModalState");
          this.error = {};
        } else {
          this.$store.commit(
            "toggleMessageModalText",
            "Проверьте поля на корректность ввода!"
          );
          this.$store.commit("showMessageModalState");
          this.error = res;
        }
      });
    },
    selectedState(keyValue, newValue) {
      this.$data[keyValue] = newValue;
    },
    categoryVision(item) {
      if (item === "") return item;
      return `${item.name}`;
    },
  },
  computed: {
    servicesList() {
      return this.$store.state?.servicesList;
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss" src="./modal.scss"></style>
