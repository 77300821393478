<template>
  <div class="section_inner-big">
    <div class="section_wrapper">
      <div class="swiper-button-prev"></div>
      <div class="swiper-container">
        <swiper
            :observer="true"
            :observeParents="true"
            :slidesPerView="1"
            :spaceBetween="20"
            :pagination="{ clickable: true }"
            :autoplay="{ delay: 5000 }"
            :navigation="{
            nextEl: '.section_substart .swiper-button-next',
            prevEl: '.section_substart .swiper-button-prev',
          }"
            :autoHeight="true"
            class="substart_slider"
        >
          <swiper-slide
              v-for="item in sliderData"
              :key="item"
              class="substart_slider__item"
          >
            <img :src="item.image" alt="">
            <div class="substart_info">
              <div class="substart_info__title">{{item.heading_h1}}</div>
              <div class="substart_info__subtitle">
                {{item.heading_h2}}
              </div>
              <div class="substart_info__description">
                <ul class="substart_info__description-left" v-html="item.text">
                </ul>
                <div class="substart_info__description-right">
                  <p>{{item.price_old}} ₽</p>
                  {{item.price_new}} ₽
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import 'swiper/swiper-bundle.min.css'
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "promo",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      defaultDataArgument: null,
      sliderOptions: {
        navigation: true,
        pagination: {
          clickable: true,
        },
        autoHeight: true,
      },
    };
  },
  methods: {
    defaultMethod() {
      defaultMethodBody;
    },
  },
  computed: {
    sliderData() {
      return this.$store.state.slider.reverse()
    }
  },
  watch: {},
};
</script>