<template>
  <div class="section_wrapper">
    <h5>Наши лучшие специалисты</h5>
    <ul class="specialists_list">
      <li
          class="specialists_item"
          v-for="item in doctorList"
          :key="item.id"
      >
        <div class="specialists_item__left">
          <img
              :src="item.photo"
              :alt="`Фото ${item.surname} ${item.name}`"
              class="specialists_item__image"
          />
          <div class="specialists_item__name">
            {{ `${item.surname} ${item.name} ${item.patronymic || ''}` }},
            <span class="specialists_item__work">{{
                doctorInfo[item.surname]?.about || 'Информация отсутствует'
              }}</span>
          </div>
          <svg
              width="30"
              height="18"
              viewBox="0 0 30 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M0.151245 2.81376L15 17.6625L29.8487 2.81376L27.3742 0.337507L15 12.7135L2.62574 0.337507L0.151245 2.81376Z"
                fill="white"
            />
          </svg>
        </div>
        <div class="specialists_item__right">
          <div class="specialists_item__name">
            {{ `${item.surname} ${item.name} ${item.patronymic || ''}` }},
            <span class="specialists_item__work">{{
                doctorInfo[item.surname]?.about || 'Информация отсутствует'
              }}</span>
          </div>
          <div class="specialists_item__description">
            {{ doctorInfo[item.surname]?.text || 'Описание отсутствует' }}
            <button
                class="specialists_item__button"
                @click="doctorOpenModal(item)"
            >
              Записаться
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "section_thebestspecialists",
  data() {
    return {
      doctorInfo: {
        Кобылинский: {
          about: "главный врач, врач УЗД, высшая категория",
          text:
              "Окончил Кубанский государственный медицинский университет...",
        },
        Гаркуша: {
          about:
              "кандидат медицинских наук, ассистент кафедры...",
          text: "Окончила Кубанский государственный медицинский университет...",
        },
      },
    };
  },
  computed: {
    servicesList() {
      return this.$store.state?.servicesList || [];
    },
    doctorList() {
      return this.servicesList
          .flatMap((item) => item.doctors || [])
          .filter((doctor) => doctor?.is_best);
    },
  },
  methods: {
    doctorOpenModal(doctor) {
      const doctorSpecial = this.servicesList.find(
          (item) => item.id === doctor.specialty
      );
      if (doctorSpecial) {
        this.$store.commit("toggleRecordModalState", "recordModal");
        this.$store.commit("updateRecordModalValues", {
          keyModal: "selectedCategory",
          valueModal: doctorSpecial,
        });
        this.$store.commit("updateRecordModalValues", {
          keyModal: "selectedDoctor",
          valueModal: doctor,
        });
      }
    },
  },
};
</script>

<style scoped></style>
