<template>
  <div class="start_specialty">
    <default-select-modal
      :class="{
        error: !selectedCategory && errorSelectors >= 1 && errorSelectors < 2,
      }"
      :funcDataVision="categoryVision"
      :value="categoryVision(selectedCategory)"
      placeholder="Выберите специальность"
      valueName="selectedCategory"
      @selectedCategory="selectedState"
      :elementList="servicesList"
    />
  </div>
  <div class="start_specialty">
    <default-select-modal
      :class="{
        error: !selectedDoctor && errorSelectors >= 1 && errorSelectors < 3,
      }"
      :value="doctorVision(selectedDoctor)"
      :funcDataVision="doctorVision"
      placeholder="Выберите специалиста"
      :disabled="!getDoctors?.length"
      valueName="selectedDoctor"
      @selectedDoctor="selectedState"
      :elementList="getDoctors"
    />
  </div>
  <div
    style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 28px; width: 100%; flex-wrap: wrap; position: relative; margin-bottom: 30px;"
  >
    <div class="start_specialty_date">
      <default-times-select-modal
        :class="{
          error: !selectedDate && errorSelectors >= 1 && errorSelectors < 4,
        }"
        :value="dateVision(selectedDate)"
        :funcDataVision="dateVision"
        placeholder="Дата"
        :disabled="!getDate?.length"
        valueName="selectedDate"
        @selectedDate="selectedState"
        :elementList="getDate"
      />
    </div>
    <div class="start_specialty_date">
      <default-times-select-modal
        :class="{ error: !selectedTime && errorSelectors >= 1 }"
        :value="timeVision(selectedTime)"
        :funcDataVision="timeVision"
        placeholder="Время"
        :disabled="!getTime?.length"
        valueName="selectedTime"
        @selectedTime="selectedState"
        :elementList="getTime"
      />
    </div>
  </div>
  <button
    type="submit"
    @click="toggleModal"
    class="start__submit openRecordModalForm"
  >
    <p>Записаться</p>
  </button>
  <div class="start__footer">
    Заполняя форму, Вы соглашаетесь с<br />
    <a :href="`${privacy_policy}`" target="_blank"
      >политикой конфиденциальности</a
    >
  </div>
</template>

<script>
import DefaultSelectModal from "../../select/DefaultSelectModal.vue";
import DefaultTimesSelectModal from "../../select/DefaultTimesSelectModal.vue";
import { api } from "../../../store/api";
import { mapState } from "vuex";
export default {
  name: "RecordMainForm",
  components: {
    DefaultSelectModal,
    DefaultTimesSelectModal,
  },
  props: ["nameValue", "textValue", "phoneValue", "policyValue"],
  emits: ["succefulModal", "errorModal"],
  data() {
    return {
      error: {},
      errorSelectors: 0,
    };
  },
  computed: {
    ...mapState(["privacy_policy"]),
    servicesList() {
      return this.$store.state?.servicesList;
    },
    selectedCategory() {
      return this.$store.state.recordModal.selectedCategory;
    },
    selectedDoctor() {
      return this.$store.state.recordModal.selectedDoctor;
    },
    selectedDate() {
      return this.$store.state.recordModal.selectedDate;
    },
    selectedTime() {
      return this.$store.state.recordModal.selectedTime;
    },
    getDoctors() {
      if (!this.$store.state.recordModal.selectedCategory.id) return [];
      return this.servicesList
        .find(
          (item) =>
            item.id === this.$store.state.recordModal.selectedCategory.id
        )
        .doctors.map((item) => {
          item.name = item.name.replace(" <br>", "");
          return item;
        })
        .filter((item) => item.is_shown);
    },
    getDate() {
      if (!this.$store.state.recordModal.selectedDoctor.id) return [];
      if (!this.getDoctors.length) return [];
      return this.getDoctors.find(
        (item) => item.id === this.$store.state.recordModal.selectedDoctor.id
      ).date_of_receipts;
    },
    getTime() {
      if (!this.$store.state.recordModal.selectedDate.id) return [];
      if (!this.getDate.length) return [];
      return this.getDate.find(
        (item) => item.id === this.$store.state.recordModal.selectedDate.id
      ).date_times_of_receipt;
    },
  },
  methods: {
    toggleModal() {
      if (this.$store.state.recordModal.stateActiveModal) {
        if (!this.selectedTime?.id) {
          this.errorSelectors = this.selectedCategory
            ? this.selectedDoctor
              ? this.selectedDate
                ? this.selectedTime
                  ? 0
                  : 4
                : 3
              : 2
            : 1;
          this.$store.commit(
            "toggleMessageModalText",
            "Проверьте меню записи на корректность ввода!"
          );
          this.$store.commit("showMessageModalState");
          return;
        }
        const postObject = {
          doctor: this.selectedDoctor?.id,
          name: this.nameValue,
          phone: this.phoneValue,
          medical_policy: this.policyValue,
          comment: this.textValue,
          time: this.selectedTime?.id,
        };
        api.fetch("POST", "appointments/", postObject).then((res) => {
          if (res?.id) {
            this.$store.commit("toggleRecordModalState", "recordModal");
            this.$store.commit(
              "toggleMessageModalText",
              "Ваша заявка отправлена!"
            );
            this.$store.commit("showMessageModalState");
            this.$emit("succefulModal");
            this.$store.commit("uploadRecordModalValues");
            this.error = {};
            this.errorSelectors = 0;
          } else {
            this.$store.commit(
              "toggleMessageModalText",
              "Проверьте поля на корректность ввода!"
            );
            this.$store.commit("showMessageModalState");
            this.error = res;
            this.$emit("errorModal", this.error);
          }
        });
        return;
      }
      this.$store.commit("toggleRecordModalState", "recordModal");
    },
    selectedState(keyValue, newValue) {
      this.$store.commit("updateRecordModalValues", {
        keyModal: keyValue,
        valueModal: newValue,
      });
    },
    categoryVision(item) {
      if (item === "") return item;
      return `${item.name}`;
    },
    doctorVision(item) {
      if (item === "") return item;
      return `${item.name} ${item.patronymic} ${item.surname}`;
    },
    dateVision(item) {
      if (item === "") return item;
      return `${item.date}`;
    },
    timeVision(item) {
      if (item === "") return item;
      return `${(item.time.split(":").textContent =
        item.time[0] +
        item.time[1] +
        item.time[2] +
        item.time[3] +
        item.time[4])}`;
    },
  },
};
</script>

<style></style>
