import SectionRecord from "../../components/section/section_record/SectionRecord.vue";
import Promo from "../../components/carousel/promo/promo.vue";
import TextIndex from "../../components/text/text_index/text_index.vue";
import FormPhone from "../../components/form/form_phone/form_phone.vue";
import section_doctor from "../../components/section/section_doctor/section_doctor.vue";
import section_step from "../../components/section/section_step/section_step.vue";
import section_advantages from "../../components/section/section_advantages/section_advantages.vue";
import form_email from "../../components/form/form_email/form_email.vue";
import Service from "../../components/carousel/service/service.vue";
export default {
  name: "index",
  components: {
    SectionRecord,
    Promo,
    TextIndex,
    FormPhone,
    section_doctor,
    section_step,
    section_advantages,
    form_email,
    Service,
  },
  props: ["main_phone_number", "questions_remain", "servicesList", "priceList"],
  mounted() {},
  data() {
    return {
      stateMap: true,
    };
  },
  methods: {
    unlockMap() {
      if (this.stateMap) this.stateMap = false;
    },
  },
};
